import { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, Helvetica, Arial, sans-serif;

  }
  
  body {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    background-color:   #f5f5f5;
    overflow-x: hidden;
  }

`;
//#E7E7E7
//#F2F2F0
//#D9D8D7
export default Global;