import React, { useState } from 'react';
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




const FormContainer = styled.form`
  width: 100%;
  max-width: 440px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center; 

  display: flex; 
  padding: 5px 10px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 1px;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 840px) {
    width: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 500px) {
    width: 420px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 420px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
`;


const Container = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: start;
  gap: 10px;
  padding: 0 10px;
  height: auto;



  @media (max-width: 1024px) {
    width: 500px;
  }
  @media (max-width: 840px) {
    width: 460px;
  }
  @media (max-width: 640px) {
    width: 440px;
  }
  @media (max-width: 500px) {
    width: 350px;
  }
`;


const Title2 = styled.h2`
  color: #4f4f4f;
  padding-top: 2vw;

 

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    padding-left: 25%;
  }
  @media (max-width: 840px) {

    display: flex;
    padding-left: 10%;
  }
  @media (max-width: 640px) {
    font-size: 22px;
    display: flex;
    padding-left: 10%;
  }
  @media (max-width: 500px) {
    font-size: 22px;
    display: flex;
    padding-left: 20%;


  }
  @media (max-width: 420px) {
    font-size: 20px;
    display: flex;
    padding-left: 20%;

  }

`;


const Input = styled.input`
  width: 400px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 840px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 500px) {
    width: 330px;
    height: 37px;
  }

  @media (max-width: 420px) {
    width: 290px;
  }
  
`;


const Label = styled.label`
  padding-top: 25px;
  font-weight: bold;
  font-size: 17px;
  text-align: left
  color: #808080;
  p{
    color: #4f4f4f;
  }
  
  @media (max-width: 500px) {
  font-size: 20px;
  }
`;


const ContainerButton = styled.div`
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  width: 400px;
  @media (max-width: 420px) {

    width: 300px;
  }

`;


const Button = styled.button`
  padding: 10px;
  margin: 20px 0px 20px 10px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: #7A7A7A;
  color: white;
  width: 120px;
  height: 40px;
  font-weight: bold;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }
  @media (max-width: 840px) {
    height: 50px;
    width: 120px;
  }
  @media (max-width: 640px) {
    height: 50px;
    width: 120px;
  }
  @media (max-width: 420px) {
    height: 50px; 
    width: 120px;
  }
`;




function Login() {
  const [logon, setLogon] = useState('');
  const [password, setPassword] = useState('');



  const handleLogin = async () => {
    try {
      const logonUpperCase = logon.toUpperCase(); // Converter para maiúsculas
      //const response = await fetch('http://192.168.100.103:8815/login', {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          logon: logonUpperCase, // Usar o valor em maiúsculas
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(data.mensagem);

        // Redefinir o formulário
        const form = document.querySelector('form');
        if (form) {
          form.reset();
        }

        const loginEncoded = encodeURIComponent(btoa(logonUpperCase)); // Usar o valor em maiúsculas
        console.log(loginEncoded);

        setTimeout(function () {
          //window.location.href = `http://192.168.100.103:3015/?login=${loginEncoded}`;
          window.location.href = `/?login=${loginEncoded}`;
        }, 2000);

      } else {
        toast.error(data.error || 'Erro desconhecido no login.');
      }

    } catch (error) {
      alert('Erro de rede ou erro desconhecido.');
    }
  };

  const handleTrocarSenha = () => {
    //const url = `http://192.168.100.103:3015/trocarsenha`;
    const url = `/trocarsenha`;
    window.location.href = url;
  };

  return (
    <FormContainer>
      <Title2>
        
        <h4>Avaliação por competências</h4>

      </Title2>
      <Container>
        <Label><p>Login:</p></Label>
        <Input
          type="text"
          placeholder="Digite aqui..."
          value={logon}
          onChange={(e) => setLogon(e.target.value)}
        />
        <Label><p>Senha:</p></Label>
        <Input
          type="password"
          placeholder="Digite aqui..."
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <ContainerButton>
          <Button type="button" onClick={handleLogin}>
            Continuar
          </Button>
          <Button type="button" onClick={handleTrocarSenha} >
            Alterar senha
          </Button>
        </ContainerButton>

      </Container>
    </FormContainer>
  );
}

export default Login;
