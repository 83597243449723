import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { writeFile, utils } from 'xlsx';
import { saveAs } from 'file-saver';
import './styles.css'
import { FaHome } from "react-icons/fa";
import { ImExit } from "react-icons/im";






const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Ajuste conforme necessário para o espaçamento desejado */
`;

const Table = styled.table`
  width: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  margin: 20px auto;


  @media (max-width: 1024px) {
    width: 965px;
    padding: 15px;
    margin: 10px 20px;
  }
  @media (max-width: 820px) {
    width: 780px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    width: 580px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 500px) {
    width: 480px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 420px) {
    width: 370px;
    padding: 5px;
    margin: 5px 20px 10px;
    
  }

  
`;


export const Thead = styled.thead``;


export const Tbody = styled.tbody`
  padding-bottom: 25px; 


`;


export const Tr = styled.tr`
  text-align: center;
  padding-bottom: 25px;
`;



export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;
export const Th2 = styled.th`
  color: #4f4f4f;
  text-align: center;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;


export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 500px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
`;


export const Th3 = styled.th`
  @media (max-width: 2640px) {
    ${(props) => props.onlyWeb2 && "display: none;"}
  }

`;


export const Td3 = styled.td`
  @media (max-width: 2640px) {
    ${(props) => props.onlyWeb2 && "display: none;"}
  }
`;


export const Td1 = styled.td`
  padding-top: 15px;
  text-align: start;
  text-overflow: ellipsis;
`;
export const Td4 = styled.td`
  width: 10%;
  padding-top: 15px;
  text-align: center;

`;


export const Td2 = styled.td`
  padding-top: 15px;
  text-align: center;
`;


const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 300px;
    height: 30px;
  }
  @media (max-width: 820px) {
    width: 300px;
    height: 35px;
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 35px;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 35px;
  }
`;

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color:  #7A7A7A ;
  color: white;

  height: 37px;
  font-weight: bold;
  margin: 8px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }
`;


const Grid = ({ users }) => {
  console.log('Valor de users:', users);
  //const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isLoading, setIsLoading] = useState(true);
  const [insertedId, setInsertedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');



  const urlParams = new URLSearchParams(window.location.search);
  const queryString = urlParams.get('login');
  let logon = null;
  let insertedId2 = null;
  console.log("insertedId:", insertedId);
  if (queryString) {
    const loginValue = queryString.split('&')[0];
    const loginDecoded = decodeURIComponent(loginValue);
    logon = atob(loginDecoded);

    // Agora, vamos verificar se existe o parâmetro "insertedId" na URL
    if (window.location.search.includes('insertedId')) {
      const insertedIdValue = window.location.search.split('insertedId:')[1];
      insertedId = parseInt(insertedIdValue, 10);
    }

    console.log("Login:", logon);
    console.log("insertedId2:", insertedId2);
  } else {
    console.log("Login não encontrado na URL.");
    window.location.href = '/login';
  }


  const loginEncoded = encodeURIComponent(btoa(logon));
  //console.log(loginEncoded);


  const handleDashboard = () => {
    const url = `/?login=${loginEncoded}`;
    window.location.href = url;
  };


  useEffect(() => {
    if (insertedId !== null) {
      setSearchTerm(insertedId);
      handleSearchButtonClick();
      console.log('useEffect - insertedId:', insertedId);
      console.log('useEffect - searchTerm:', searchTerm);
    }
  }, [insertedId]);
  
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    console.log('handleSearchInputChange - searchTerm:', searchTerm);
  };
  


  const handleSearchButtonClick = () => {
    console.log('Filtrando pelo valor:', searchTerm);
    const filteredUsers = users.filter((item) =>
      Object.values(item).some((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return String(value).toLowerCase().includes(searchTerm.toLowerCase());
          }
        }
        return false;
      })
    );
  
    console.log('filteredUsers:', filteredUsers); // Adicione esta linha
  
    setFilteredUsers(filteredUsers);
    setIsLoading(false);
  };
  

  const exportToExcel = () => {
    // Crie um novo livro de trabalho (workbook)
    const wb = utils.book_new();

    // Crie uma nova planilha (worksheet)
    const ws = utils.json_to_sheet(filteredUsers);

    // Adicione a planilha ao livro de trabalho
    utils.book_append_sheet(wb, ws, 'Planilha 1');

    // Gere o arquivo Excel
    const excelBuffer = writeFile(wb, 'dadosExportados.xlsx');

    // Salve o arquivo ou abra-o para download
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'dadosExportados.xlsx');
  };

  const handleExit = () => {
    const url = `/`;
    window.location.href = url;
  };


  return (

    <div>
      <Div>
        <InputGroup>
          <Input
            type="text"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          <Button onClick={() => setInsertedId(searchTerm)}>Pesquisar</Button>
          <Button onClick={exportToExcel}>Exportar para Excel</Button>
        </InputGroup>
      </Div>
      {isLoading ? (
        <p></p>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th onlyWeb2>ID:</Th>
              <Th onlyWeb>Avaliador:</Th>
              <Th onlyWeb>Avaliado:</Th>
              <Th onlyWeb>Avaliação:</Th>
              <Th onlyWeb>Pergunta:</Th>
              <Th onlyWeb>Resposta:</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((item, i) => (
              <React.Fragment key={i}>

                <Tr>
                  <Td1 width="3%" onlyWeb>{item.id_aplicacaoavaliacao}</Td1>
                  <Td1 width="15%" onlyWeb>{item.nome_avaliador}</Td1>
                  <Td1 width="15%" onlyWeb>{item.nome_avaliado}</Td1>
                  <Td1 width="20%" onlyWeb>{item.descricao_sistema_avaliacao}</Td1>
                  <Td1 width="auto" onlyWeb>{item.id_item_avaliacao} - {item.descricao_item_avaliacao}</Td1>
                  <Td2 width="auto" onlyWeb>{item.descricao_faixa_avaliacao}</Td2>
                </Tr>
              </React.Fragment>
            ))}
          </Tbody>
        </Table>

      )}
      {/*
      {isLoading ? (
        <p></p>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th onlyWeb2>ID:</Th>
              <Th onlyWeb>Avaliação:</Th>
              <Th2 onlyWeb>Avaliador:</Th2>
              <Th2 onlyWeb>Avaliado:</Th2>
              <Th2 width="10%" onlyWeb>Resposta:</Th2>

            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((item, i) => (
              <React.Fragment key={i}>
                {i === 0 && (
                  <Tr className="custom-tr">
                    <Td1 width="3%">{item.id_aplicacaoavaliacao}</Td1>
                    <Td1 width="40%">{item.descricao_sistema_avaliacao}</Td1>
                    <Td4 width="20%">{item.nome_avaliador}</Td4>
                    <Td4 width="20%">{item.nome_avaliado}</Td4>
                  </Tr>
                )}
                <Tr>
                  <Td1 >
                    {item.id_aplicacaoavaliacao}
                  </Td1>
                  <Td1 colSpan="3" >
                    {item.id_item_avaliacao} - {item.descricao_item_avaliacao}
                  </Td1>
                  <Tr>
                    <Td4>
                      {item.descricao_faixa_avaliacao}
                    </Td4>
                  </Tr>
                </Tr>
              </React.Fragment>
            ))}
          </Tbody>

        </Table>
        )}*/}
      <Button onClick={handleDashboard} style={{ position: "absolute", top: 80, left: 10 }} title={"Voltar"}>
        <FaHome title={"Voltar"} />
      </Button>
      <Button onClick={handleExit} style={{ position: "absolute", top: 130, left: 10 }} title={"Logout"}>
        <ImExit title={"Sair"} />
      </Button>
    </div >
  );
};

export default Grid;
