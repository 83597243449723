import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './Dashboard.js'
import Login from './Login.js'
import Modulos from './Modulos'
import Perguntas from './Perguntas'
import Usuarios from './Usuarios'
import Avaliacao from './Avaliacao'
import Relatorio from './Relatorio'
import TrocarSenha from './TrocarSenha.js'
import Autoavaliacao from "./Autoavaliacao.js";

function App() {
    return (
        <Router >
            
            <Routes>

                <Route exact path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/modulos" element={<Modulos />} />
                <Route path="/perguntas" element={<Perguntas />} />
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/avaliacao" element={<Avaliacao />} />
                <Route path="/relatorio" element={<Relatorio />} />
                <Route path="/trocarsenha" element={<TrocarSenha />} />
                <Route path="/autoavaliacao" element={<Autoavaliacao />} />

            </Routes>

        </Router>

    )
}
export default App;