import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FaHome } from "react-icons/fa";
import { ImExit } from "react-icons/im";


const FormContainer = styled.form`
  width: 1160px;
  background-color: #fff;
  display: flex; 
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  align-items: flex-end;
  gap: 5px;
  margin: 10px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 924px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 820px) {
    width: 720px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 540px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 500px) {
    width: 440px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 420px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 905px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 800px;
    height: 35px;
  }
  @media (max-width:820px) {
    width: 690px;
    height: 35px;
  }
  @media (max-width: 640px) {
    width: 520px;
    height: 35px;
  }
  @media (max-width: 500px) {
    width: 420px;
    height: 35px;
  }
  @media (max-width: 420px) {
    width: 320px;
    height: 35px;
  }
`;

const Input2 = styled.input`
  width: 50px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 820px) {
    width: 80px;
  }

  @media (max-width: 640px) {
    height: 30px;
  }
  @media (max-width: 500px) {
    height: 35px;
    width: 80px;
  }
  
`;

const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
  padding-left: 1px;

  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  background-color:  #7A7A7A;
  color: white;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color:  #000000;
  }
`;


const Form = ({ getUsers, onEdit, setOnEdit }) => {
  const [loginEncoded, setLogonEncoded] = useState([]);
  const ref = useRef();



  useEffect(() => {
    // Obter a URL atual
    const currentUrl = window.location.href;

    // Verificar se a URL contém o parâmetro 'login'
    if (currentUrl.includes('?login=')) {
      // Extrair o valor do parâmetro 'login' da URL
      const loginEncoded = currentUrl.split('?login=')[1];

      // Decodificar o valor do parâmetro 'login'
      const logon = atob(decodeURIComponent(loginEncoded));

      console.log('useEffect', logon);
      setLogonEncoded(loginEncoded);
      // Verificar se logon está presente
      if (!logon) {
        // Redirecionar para a página de login em .php (substitua pela URL correta)
        window.location.href = '/login';
      }
    } else {
      // Se o parâmetro 'login' não estiver presente na URL, redirecionar para a página de login
      window.location.href = '/login';
    }
  }, []);




  useEffect(() => {
    if (onEdit) {
      const user = ref.current;

      user.descricao.value = onEdit.descricao;
      user.mascara.value = onEdit.mascara;
    }
  }, [onEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    const descricaoValue = user.descricao.value;
    const mascaraValue = user.mascara.value;

    if (!descricaoValue || !mascaraValue) {
      return toast.warn("Preencha todos os campos!");
    }

    if (onEdit) {
      await axios
        .put("http://192.168.100.103:8815/modulos/" + onEdit.id_avaliacao, {
        //.put("/api/modulos/" + onEdit.id_avaliacao, {
          descricao: user.descricao.value,
          mascara: user.mascara.value,
        })
        .then(({ data }) => toast.success(data))
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.error;
            const details = error.response.data.details;
            const dbError = error.response.data.dbError;
            console.log("Error:", errorMessage);
            console.log("Details:", details);
            console.log("Database Error:", dbError);
            toast.error("Erro ao editar: " + errorMessage);
          }
        });
    } else {
      await axios
        .post("http://192.168.100.103:8815/modulos/", {
        //.post("/api/modulos/", {
          descricao: user.descricao.value,
          mascara: user.mascara.value,
        })
        .then(({ data }) => toast.success(data))
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.error;
            const details = error.response.data.details;
            const dbError = error.response.data.dbError;
            console.log("Error:", errorMessage);
            console.log("Details:", details);
            console.log("Database Error:", dbError);
            toast.error("Erro ao adicionar: " + errorMessage);
          }
        });
    }

    user.descricao.value = "";
    user.mascara.value = "";
    setOnEdit(null);
    getUsers();
  };

  const handleDashboard = () => {
    //const url = `http://192.168.100.103:3015/?login=${loginEncoded}`;
    const url = `/?login=${loginEncoded}`;
    window.location.href = url;
  };

  const handleExit = () => {
    const url = `/`;
    window.location.href = url;
  };

  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Descrição:</Label>
          <Input name="descricao" type="text" />
        </InputArea>
        <InputArea>
          <Label>Máscara:</Label>
          <Input2 name="mascara" />
        </InputArea>

        <ButtonContainer>
          <Button title="Salvar" type="submit">
            Salvar
          </Button>
          <Button title="Novo" type="button" onClick={() => window.location.reload()}>
            Novo
          </Button>
        </ButtonContainer>
      </FormContainer>
      <Button onClick={handleDashboard} style={{ position: "absolute", top: 80, left: 10 }} title={"Voltar"}>
        <FaHome title={"Voltar"}/>
      </Button>
      <Button onClick={handleExit} style={{ position: "absolute", top: 130, left: 10 }} title={"Logout"}>
        <ImExit title={"Sair"} />
      </Button>

    </div>

  );
};

export default Form;


